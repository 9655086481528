import React, { useEffect, useState } from 'react';
import { useForm, UseFormReturn, UseFormStateReturn } from 'react-hook-form';
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Button,
  Spinner,
  Select,
  Flex,
} from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  createPlan, fetchCurrentPlan, fetchPlans, Plan, updatePlan,
} from '../features/plans/plans';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { RootState } from '../app/store';
import { fetchCustomers } from '../features/customers/customers';
import { FormControlInput } from './forms';
import { NeptuneAutoComplete } from './forms/NeptuneAutoComplete';

export const PlanForm = () => {
  const formHookRet = useForm();

  const emptyPlan = {
  } as Plan;

  const [plan, setPlan] = useState({} as Plan);

  const { planId } = useParams();
  const dispatch = useAppDispatch();
  const plansState = useAppSelector((state: RootState) => state.plans);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    if (planId) {
      dispatch(fetchCurrentPlan({ id: planId } as Plan));
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (planId !== '') {
      setPlan(plansState.current);
      formHookRet.setValue('aggregator_id', plansState.current.aggregator_id);
      formHookRet.setValue('aggregator_plan_id', plansState.current.aggregator_plan_id);
      formHookRet.setValue('cost', plansState.current.cost);
      formHookRet.setValue('description', plansState.current.description);
      formHookRet.setValue('down_mbps', plansState.current.down_mbps);
      formHookRet.setValue('name', plansState.current.name);
      formHookRet.setValue('neptune_id', plansState.current.neptune_id);
      formHookRet.setValue('id', plansState.current.id);
      formHookRet.setValue('stripe_product_id', plansState.current.stripe_product_id);
      formHookRet.setValue('up_mbps', plansState.current.up_mbps);
    }
  }, [plansState.current]);

  const onSubmit = (values: any) => {
    console.log('VALUES', values);
    values = {
      ...values,
      up_mbps: Number(values.up_mbps),
      down_mbps: Number(values.down_mbps),
      cost: Number(values.cost),
    };
    if (planId) {
      dispatch(updatePlan(values));
    } else {
      dispatch(createPlan(values));
    }

    navigate('/dashboard/plans');
  };

  if (plansState.status === 'loading' || loading) {
    return <Spinner />;
  }

  return (
    <form onSubmit={formHookRet.handleSubmit(onSubmit)}>
      <FormControl key="plan_id" isInvalid={!!formHookRet.formState.errors.id} />

      <FormControlInput
        id="neptune_id"
        validate={(val: string) => (!val.match(/^.+/) ? 'neptuned id is required' : true)}
        label="Neptune ID"
        formHook={formHookRet}
      />

      <FormControlInput
        id="aggregator_plan_id"
        validate={(val: string) => (!val.match(/^.+/) ? 'aggregator id is required' : true)}
        label="Aggreagtor ID"
        formHook={formHookRet}
      />

      <FormControlInput
        id="up_mbps"
        validate={(val: number) => (val === 0 ? 'up mbps id is required' : true)}
        label="Up speed"
        formHook={formHookRet}
      />

      <FormControlInput
        id="down_mbps"
        validate={(val: number) => (val === 0 ? 'down mbps id is required' : true)}
        label="Down speed"
        formHook={formHookRet}
      />

      <Button mt={4} colorScheme="teal.500" isLoading={formHookRet.formState.isSubmitting} type="submit">
        <Flex
          align="center"
          p="4"
          mx="4"
          borderRadius="lg"
          role="group"
          cursor="pointer"
          _hover={{
            bg: 'teal.500',
            color: 'black',
          }}
        >
          Submit
        </Flex>
      </Button>
    </form>
  );
};
