import { Flex, FormControl, FormLabel } from '@chakra-ui/react';
import {
  AutoComplete, AutoCompleteInput, AutoCompleteItem, AutoCompleteList,
} from '@choc-ui/chakra-autocomplete';
import React, { useEffect, useMemo, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { fetchCustomers } from '../../features/customers/customers';
import nbnCoAddressSlice from '../../features/nbn_co_places/nbnCoAddressSlice';
import nbnCoPlacesSlice, { fetchPlaces, selectNBNCoPlaces } from '../../features/nbn_co_places/nbnCoPlacesSlice';
import { NBNCoAddress } from '../../models/nbn';

export const NeptuneAutoComplete = () => {
  const dispatch = useAppDispatch();
  const placesState = useAppSelector(selectNBNCoPlaces);

  const [inputValue, setInputValue] = useDebounce('', 100);
  const [selectedAddress, setSelectedAddress] = useState<NBNCoAddress | null>(null);

  useEffect(() => {
    if (inputValue === '') {
      dispatch(nbnCoPlacesSlice.actions.reset());
      return;
    }
    const inFlight = dispatch(fetchPlaces(inputValue));
  }, [inputValue, dispatch]);

  const noOptionsText = useMemo(() => {
    if (placesState.status === 'loading') {
      return 'Searching...';
    }

    if (inputValue === '') {
      return 'Please type your address';
    }

    return 'No locations found';
  }, [inputValue, placesState.status]);

  console.log('SELECTED', selectedAddress);

  return (
    <FormControl>
      <FormLabel>AutoComplete Addr</FormLabel>
      <AutoComplete openOnFocus disableFilter isLoading={placesState.status === 'loading'}>
        <AutoCompleteInput
          background="black"
          value={inputValue}
          onChange={(event) => {
            event.preventDefault();
            setInputValue(event.target.value);
          }}
        />
        <AutoCompleteList>
          {placesState.value.map((addr) => (
            <AutoCompleteItem
              bg="black"
              key={addr.id}
              value={addr.formattedAddress}
              onClick={() => {
                setSelectedAddress(addr);
                setInputValue(addr.formattedAddress);
              }}
              textTransform="capitalize"
            >
              {addr.formattedAddress}
            </AutoCompleteItem>
          ))}
        </AutoCompleteList>
      </AutoComplete>
    </FormControl>
  );
};
