import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Client } from '../../http';
import { RootState } from '../../app/store';

export interface Me {
  authenticate: string;
  token: string | undefined;
}

export interface meState {
  value: Me;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: meState = {
  value: {
    authenticate: '',
    token: '',
  },
  status: 'loading',
};

export const fetchMe = createAsyncThunk(
  'me/fetchMe',
  async (token: string | undefined) => {
    const response = await Client.get('/api/v1/admin/me', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    // The value we return becomes the `fulfilled` action payload
    return { ...response.data };
  },
);

export const meSlice = createSlice({
  name: 'me',
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string | undefined>) => {
      state.value.token = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMe.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchMe.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value.authenticate = action.payload.authenticate;
      })
      .addCase(fetchMe.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const { setToken } = meSlice.actions;
export const selectToken = (state: RootState) => state.me.value.token;
export const selectAuthStatus = (state: RootState) => state.me.value.authenticate;

export default meSlice.reducer;
