import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Client } from '../../http';
import { RootState } from '../../app/store';

export interface Plan {
  id: string;
  sk: string;
  neptune_id: string;
  cost: number;
  name: string;
  description: string;
  aggregator_id: string;
  aggregator_plan_id: string;

  up_mbps: number;
  down_mbps: number;

  stripe_payment_link: string;
  stripe_product_id: string;
}

export interface planState {
  value: Array<Plan>;
  current: Plan;
  nextPageToken: string;
  previousPageToken: string;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: planState = {
  current: {} as Plan,
  value: [],
  nextPageToken: '',
  previousPageToken: '',
  status: 'idle',
};

export const fetchPlans = createAsyncThunk<
  any,
  string | undefined
>('plans/fetchPlans', async () => {
  const response = await Client.get<Plan>(
    '/api/v1/admin/neptune/plans',
  );
  return response.data;
});

export const createPlan = createAsyncThunk<
  Plan,
  Plan,
  {state: RootState}
>('plans/createPlan', async (svc: Plan) => {
  const response = await Client.post<Plan>(
    '/api/v1/admin/neptune/plans',
    svc,
  );
  return response.data;
});

export const updatePlan = createAsyncThunk<
  Plan,
  Plan,
  {state: RootState}
>('plans/updatePlan', async (svc: Plan) => {
  const response = await Client.put<Plan>(
    '/api/v1/admin/neptune/plans',
    svc,
  );
  return response.data;
});

export const fetchCurrentPlan = createAsyncThunk<
  Plan,
  Plan,
  {state: RootState}
>('plans/fetchCurrentPlan', async ({ id }: Plan) => {
  const response = await Client.get<Plan>(
    `/api/v1/admin/neptune/plans/${id}`,
  );
  return response.data;
});

export const plansSlice = createSlice({
  name: 'plans',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(fetchCurrentPlan.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCurrentPlan.fulfilled, (state, action) => {
        state.status = 'idle';
        state.current = action.payload;
      })
      .addCase(fetchCurrentPlan.rejected, (state) => {
        state.status = 'failed';
      })

      .addCase(fetchPlans.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPlans.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload;
      })
      .addCase(fetchPlans.rejected, (state) => {
        state.status = 'failed';
      })

      .addCase(updatePlan.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updatePlan.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = state.value.flatMap((v) => (v.id === action.payload.id ? [action.payload] : [v]));
      })
      .addCase(updatePlan.rejected, (state) => {
        state.status = 'failed';
      })

      .addCase(createPlan.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createPlan.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value.push(action.payload);
      })
      .addCase(createPlan.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const selectPlans = (state: RootState) => state.plans.value;
export default plansSlice.reducer;
