import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { NBNCoAddress } from '../../models/nbn';

export interface NBNCoAddressState {
  value: NBNCoAddress | null;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: NBNCoAddressState = {
  value: null,
  status: 'idle',
};

export const nbnCoAddressSlice = createSlice({
  name: 'nbnCoPlaces',
  initialState,
  reducers: {
    setAddress: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const selectNBNCoAddress = (state: RootState) => state.nbnCoAddress.value;
export default nbnCoAddressSlice;
