import React, { useEffect, useState } from 'react';
import { useForm, UseFormReturn, UseFormStateReturn } from 'react-hook-form';
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Button,
  Spinner,
  Select,
  Flex,
} from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  createSubscription, fetchCurrentSubscription, fetchSubscriptions, Subscription, updateSubscription,
} from '../features/subscriptions/subscriptions';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { RootState } from '../app/store';
import { fetchCustomers } from '../features/customers/customers';
import { FormControlInput } from './forms';
import { NeptuneAutoComplete } from './forms/NeptuneAutoComplete';

export const SubscriptionForm = () => {
  const formHookRet = useForm();

  const [subscription, setSubscription] = useState({} as Subscription);

  const { subscriptionId } = useParams();
  const dispatch = useAppDispatch();
  const subscriptionState = useAppSelector((state: RootState) => state.subscriptions);
  const customersState = useAppSelector((state: RootState) => state.customers);
  const plansState = useAppSelector((state: RootState) => state.plans);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(fetchCustomers());
    setLoading(true);
    if (subscriptionId !== '') {
      dispatch(fetchCurrentSubscription(subscriptionId));
    }

    setLoading(false);
  }, []);

  useEffect(() => {
    if (subscriptionId !== '') {
      formHookRet.setValue('aggregator_service_id', subscriptionState.current.aggregator_service_id);
      formHookRet.setValue('avc_id', subscriptionState.current.avc_id);
      formHookRet.setValue('building_level', subscriptionState.current.building_level);
      formHookRet.setValue('building_name', subscriptionState.current.building_name);
      formHookRet.setValue('created_at', subscriptionState.current.created_at);
      formHookRet.setValue('customer_id', subscriptionState.current.customer_id);
      formHookRet.setValue('formatted_address', subscriptionState.current.formatted_address);
      formHookRet.setValue('infrastructure_id', subscriptionState.current.infrastructure_id);
      formHookRet.setValue('infrastructure_port', subscriptionState.current.infrastructure_port);
      formHookRet.setValue('infrastructure_product_id', subscriptionState.current.infrastructure_product_id);
      formHookRet.setValue('ip', subscriptionState.current.ip);
      formHookRet.setValue('ip6', subscriptionState.current.ip6);
      formHookRet.setValue('lat', subscriptionState.current.lat);
      formHookRet.setValue('lon', subscriptionState.current.lon);
      formHookRet.setValue('manual_transfer', subscriptionState.current.manual_transfer);
      formHookRet.setValue('plan_id', subscriptionState.current.plan_id);
      formHookRet.setValue('poi_id', subscriptionState.current.poi_id);
      formHookRet.setValue('poi_name', subscriptionState.current.poi_name);
      formHookRet.setValue('postcode', subscriptionState.current.postcode);
      formHookRet.setValue('service_class', subscriptionState.current.service_class);
      formHookRet.setValue('street', subscriptionState.current.state);
      formHookRet.setValue('street_number', subscriptionState.current.street_number);
      formHookRet.setValue('street', subscriptionState.current.street);
      formHookRet.setValue('stripe_subscription_id', subscriptionState.current.stripe_subscription_id);
      formHookRet.setValue('suburb', subscriptionState.current.suburb);
      formHookRet.setValue('technology_type', subscriptionState.current.technology_type);
      formHookRet.setValue('unit_number', subscriptionState.current.unit_number);
      formHookRet.setValue('traffic_class', subscriptionState.current.traffic_class);
      formHookRet.setValue('location_id', subscriptionState.current.location_id);

      setSubscription(subscriptionState.current);
    }
  }, [subscriptionState.current, subscriptionState.status]);

  const onSubmit = (values: any) => {
    if (subscriptionId) {
      dispatch(updateSubscription({ ...values, id: `SUBSCRIPTION#${subscriptionId}` }));
    } else {
      dispatch(createSubscription(values));
    }

    subscriptionState.value.forEach((sub) => {
      if (sub.stripe_subscription_id === values.stripe_subscription_id) {
        navigate('/dashboard/subscriptions');
      }
    });
  };

  if (subscriptionState.status === 'loading' || loading) {
    return <Spinner />;
  }

  return (
    <form onSubmit={formHookRet.handleSubmit(onSubmit)}>
      <FormControl key="customer_id" isInvalid={!!formHookRet.formState.errors.customer_id}>
        <FormLabel htmlFor="customer_id">Customer</FormLabel>
        <Select
          id="customer_id"
          {...formHookRet.register('customer_id', {
            required: 'customer_id is required',
          })}
        >
          {customersState.value.map((cust) => (
            <option key={cust.id} value={cust.id}>
              {cust.id}
              {' '}
              |
              {' '}
              {cust.stripe_customer_id}
              {' '}
              |
              {' '}
              {cust.first_name}
              {' '}
              {cust.last_name}
            </option>
          ))}
        </Select>
        <FormErrorMessage>
          {(formHookRet.formState.errors.customer_id?.message?.toString() || '')}
        </FormErrorMessage>
      </FormControl>

      <FormControlInput
        id="stripe_subscription_id"
        validate={(val: any) => (!val.match(/^sub_.+/) ? 'stripe sub must start with sub_XXXXX' : true)}
        label="Stripe Subscription"
        formHook={formHookRet}
      />

      <FormControlInput
        id="aggregator_service_id"
        validate={(val: any) => (!val.match(/^[0-9]+/) ? 'superloop id must be number' : true)}
        label="Superlopp Service"
        formHook={formHookRet}
      />

      <FormControlInput
        id="avc_id"
        validate={(val: any) => (!val.match(/^AVC[0-9]+/) ? 'avc id must start with AVC and follow numbers' : true)}
        label="AVC"
        formHook={formHookRet}
      />

      <FormControlInput
        id="location_id"
        validate={(val: any) => (!val.match(/^LOC[0-9]+/) ? 'loc id must start with LOC and follow numbers' : true)}
        label="LOCID"
        formHook={formHookRet}
      />

      <FormControlInput
        id="ip"
        validate={(val: any) => (!val.match(/^103\.252\.(194|195)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/) ? 'ip address is invalid' : true)}
        label="IP"
        formHook={formHookRet}
      />

      <FormControl pt={2} key="plan_id" isInvalid={!!formHookRet.formState.errors.plan_id}>
        <FormLabel htmlFor="plan_id">Plan</FormLabel>
        <Select
          id="plan_id"
          {...formHookRet.register('plan_id', {
            required: 'plan_id is required',
          })}
        >
          {plansState.value.map((plan) => (
            <option key={plan.id} value={plan.id}>
              {plan.neptune_id}
              {' '}
              |
              {' '}
              $
              {plan.cost}
              {' '}
              |
              {' '}
              {plan.stripe_product_id}
            </option>
          ))}
        </Select>
        <FormErrorMessage>
          {(formHookRet.formState.errors.customer_id?.message?.toString() || '')}
        </FormErrorMessage>
      </FormControl>

      <NeptuneAutoComplete />
      <FormControlInput
        id="formatted_address"
        validate
        label="Address"
        formHook={formHookRet}
      />

      <Button mt={4} colorScheme="teal.500" isLoading={formHookRet.formState.isSubmitting} type="submit">
        <Flex
          align="center"
          p="4"
          mx="4"
          borderRadius="lg"
          role="group"
          cursor="pointer"
          _hover={{
            bg: 'teal.500',
            color: 'black',
          }}
        >
          Submit
        </Flex>
      </Button>
    </form>
  );
};
