import { Button } from '@chakra-ui/react';
import React from 'react';

const LogoutButton = () => (
  <Button
    marginLeft="10px"
    color="white"
    colorScheme="pink"
    borderRadius="20px"
    onClick={() => console.log('log out')}
  >
    Log Out
  </Button>
);

export default LogoutButton;
