import {
  Text, Box, Stack, Divider, Center, Grid, Link,
  GridItem,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import { useAppSelector } from '../app/hooks';
import { RootState } from '../app/store';
import { SidebarWithHeader } from './SideBarWithHeader';

// const cfg: IBackendConfig = getConfig();

export interface DashboardProps {
  children: ReactNode
}

const Dashboard = ({ children }: DashboardProps) => {
  const customerErrors = useAppSelector((state: RootState) => (state.customers?.error));
  return (

    <SidebarWithHeader>
      <Grid
        templateAreas={`"header"
                      "main"
                      "footer"`}
        h="100%"
        minHeight="100vh"
        flexGrow="column nowrap"
        gap="1"
      >
        <GridItem>
          <Box width="full">
            <Stack spacing={4} width="100%" direction="column">
              <Stack
                alignItems="center"
                justifyContent={{
                  base: 'flex-start',
                  md: 'space-around',
                }}
                direction={{
                  base: 'column',
                  md: 'row',
                }}
              >
                <Stack
                  width={{
                    base: '100%',
                    md: '60%',
                  }}
                >
                  <Text textAlign="center">
                    Create or update subscriptions and other objects.
                  </Text>
                </Stack>
              </Stack>
              {customerErrors
                && (
                <Alert status="error">
                  <AlertIcon />
                  <AlertTitle color="black">{customerErrors}</AlertTitle>
                </Alert>
                )}
              <Divider />
              {children}
              <Divider />
            </Stack>
          </Box>
        </GridItem>
        <GridItem bg="black" area="footer">
          <Center style={{ paddingTop: '0%' }}>
            <Text fontSize="xxs" color="white">
              © Neptune Internet 2024. All rights reserved.
              {' '}
            </Text>
          </Center>

          <Center style={{ paddingTop: '0%' }}>
            <Text fontSize="xxs" color="teal.500">
              <Link href="https://status.neptune.net.au" isExternal>Status Page</Link>
            </Text>
          </Center>
        </GridItem>
      </Grid>
    </SidebarWithHeader>

  );
};

export default Dashboard;
