import React, { useEffect, useState } from 'react';
import { useForm, UseFormReturn, UseFormStateReturn } from 'react-hook-form';
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Button,
  Spinner,
  Select,
  Flex,
} from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import customers, {
  fetchCustomers, Customer, createCustomer, fetchCurrentCustomer, updateCustomer,
} from '../features/customers/customers';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { RootState } from '../app/store';
import { FormControlInput } from './forms';

export const CustomerForm = () => {
  const formHookRet = useForm();

  const emptyCustomer = {
  } as Customer;

  const [customer, setCustomer] = useState({} as Customer);

  const { customerId } = useParams();
  const dispatch = useAppDispatch();
  const customerState = useAppSelector((state: RootState) => state.customers);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    if (customerId) {
      dispatch(fetchCurrentCustomer({ id: customerId } as Customer));
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (customerId !== '') {
      setCustomer(customerState.currentCustomer);
      formHookRet.setValue('email', customerState.currentCustomer.email);
      formHookRet.setValue('phone', customerState.currentCustomer.phone);
      formHookRet.setValue('last_name', customerState.currentCustomer.last_name);
      formHookRet.setValue('first_name', customerState.currentCustomer.first_name);
      formHookRet.setValue('id', customerState.currentCustomer.id);
      formHookRet.setValue('stripe_customer_id', customerState.currentCustomer.stripe_customer_id);
    }
  }, [customerState?.currentCustomer]);

  const onSubmit = (values: any) => {
    if (customerId) {
      dispatch(updateCustomer(values));
    } else {
      dispatch(createCustomer(values));
    }

    customerState.value.forEach((cust) => {
      if (cust.email === values.email) {
        navigate('/dashboard/customers');
      }
    });
  };

  if (customerState.status === 'loading' || loading) {
    return (<Spinner />);
  }

  return (
    <form onSubmit={formHookRet.handleSubmit(onSubmit)}>
      <FormControl key="id" isInvalid={!!formHookRet.formState.errors.id} />

      <FormControlInput
        id="email"
        validate={(val: any) => (!val.match(/^.+/) ? 'email is required' : true)}
        label="Email"
        formHook={formHookRet}
      />

      <FormControlInput
        id="first_name"
        validate={(val: any) => (!val.match(/^.+/) ? 'first name is required' : true)}
        label="First Name"
        formHook={formHookRet}
      />

      <FormControlInput
        id="last_name"
        validate={(val: any) => (!val.match(/^.+/) ? 'last name is required' : true)}
        label="Last Name"
        formHook={formHookRet}
      />

      <FormControlInput
        id="phone"
        validate={(val: any) => (!val.match(/^.+/) ? 'phone is required' : true)}
        label="Phone"
        formHook={formHookRet}
      />

      {customerId
        && (
        <FormControlInput
          id="stripe_customer_id"
          validate={(val: any) => (!val.match(/^.+/) ? ' stripe is required' : true)}
          label="Stripe ID"
          formHook={formHookRet}
        />
        )}

      <Button mt={4} colorScheme="teal.500" isLoading={formHookRet.formState.isSubmitting} type="submit">
        <Flex
          align="center"
          p="4"
          mx="4"
          borderRadius="lg"
          role="group"
          cursor="pointer"
          _hover={{
            bg: 'teal.500',
            color: 'black',
          }}
        >
          Submit
        </Flex>
      </Button>
    </form>
  );
};
