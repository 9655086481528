import React, { useEffect } from 'react';

//
import {
  Column,
  Table,
  ColumnDef,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  flexRender,
  RowData,
} from '@tanstack/react-table';
import {
  Box, Button, Input, Select,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { Subscription } from '../../features/subscriptions/subscriptions';

declare module '@tanstack/react-table' {
  interface TableMeta<TData extends RowData> {
    updateData: (rowIndex: number, columnId: string, value: unknown) => void
  }
}

// Give our default column cell renderer editing superpowers!
const defaultColumn: Partial<ColumnDef<Subscription>> = {
  cell: ({
    getValue, row: { index }, column: { id }, table,
  }) => {
    const initialValue = getValue();
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue);

    // When the input is blurred, we'll call our table meta's updateData function
    const onBlur = () => {
      table.options.meta?.updateData(index, id, value);
    };

    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    return (
      <Input
        value={value as string}
        onChange={(e) => setValue(e.target.value)}
        onBlur={onBlur}
      />
    );
  },
};

function useSkipper() {
  const shouldSkipRef = React.useRef(true);
  const shouldSkip = shouldSkipRef.current;

  // Wrap a function with this to skip a pagination reset temporarily
  const skip = React.useCallback(() => {
    shouldSkipRef.current = false;
  }, []);

  React.useEffect(() => {
    shouldSkipRef.current = true;
  });

  return [shouldSkip, skip] as const;
}

const Filter = ({
  column,
  table,
}: {
  column: Column<any, any>
  table: Table<any>
}) => {
  const firstValue = table
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id);

  const columnFilterValue = column.getFilterValue();

  return typeof firstValue === 'number' ? (
    <div className="flex space-x-2">
      <input
        type="number"
        value={(columnFilterValue as [number, number])?.[0] ?? ''}
        onChange={(e) => column.setFilterValue((old: [number, number]) => [
          e.target.value,
          old?.[1],
        ])}
        placeholder="Min"
        className="w-24 border shadow rounded"
      />
      <input
        type="number"
        value={(columnFilterValue as [number, number])?.[1] ?? ''}
        onChange={(e) => column.setFilterValue((old: [number, number]) => [
          old?.[0],
          e.target.value,
        ])}
        placeholder="Max"
        className="w-24 border shadow rounded"
      />
    </div>
  ) : (
    <Input
      type="text"
      value={(columnFilterValue ?? '') as string}
      onChange={(e) => column.setFilterValue(e.target.value)}
      placeholder="Search..."
      className="w-36 border shadow rounded"
    />
  );
};

export interface SubscriptionsProps {
  data: Subscription[]
}

export const SubscriptionsTable = ({ data }: SubscriptionsProps) => {
  const rerender = React.useReducer(() => ({}), {})[1];

  const columns = React.useMemo<ColumnDef<Subscription>[]>(
    () => [
      {
        header: 'Subscription',
        footer: (params) => params.column.id,
        columns: [
          {
            accessorKey: 'id',
            header: 'ID',
            accessorFn: (row) => row?.id.replaceAll('SUBSCRIPTION#', ''),
            footer: (params) => params.column.id,
          },
          {
            accessorFn: (row) => row.avc_id,
            id: 'avc_id',
            header: 'AVC',
            footer: (params) => params.column.id,
          },
          {
            accessorKey: 'LocationID',
            header: 'LOC_ID',
            id: 'location_id',
            accessorFn: (row) => row.location_id,
            footer: (params) => params.column.id,
          },
          {
            accessorKey: 'IP',
            accessorFn: (row) => row.ip,
            footer: (params) => params.column.id,
          },
          {
            accessorKey: 'Formatted Address',
            accessorFn: (row) => row?.formatted_address,
            footer: (params) => params.column.id,
          },
          {
            accessorKey: 'Customer ID',
            accessorFn: (row) => row?.customer_id,
            footer: (params) => params.column.id,
          },
          {
            accessorKey: 'Plan ID',
            accessorFn: (row) => row?.plan_id,
            footer: (params) => params.column.id,
          },
        ],
      },
    ],
    [],
  );

  const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper();

  const table = useReactTable({
    data,
    columns,
    defaultColumn,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    autoResetPageIndex,
    // Provide our updateData function to our table meta
    debugTable: true,
  });

  const navigate = useNavigate();

  return (
    <div className="p-2">
      <div className="h-2" />
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder ? null : (
                    <div>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                      {header.column.getCanFilter() ? (
                        <div>
                          <Filter column={header.column} table={table} />
                        </div>
                      ) : null}
                    </div>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} onClick={() => navigate(`${cell.getValue()}`)}>
                  {flexRender(
                    cell.column.columnDef.cell,
                    cell.getContext(),
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="h-2" />
      <div className="flex items-center gap-2">
        <Button
          m={[1, 2]}
          onClick={() => table.setPageIndex(0)}
          disabled={!table.getCanPreviousPage()}
        >
          {'<<'}
        </Button>
        <Button
          m={[1, 2]}
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          {'<'}
        </Button>
        <Button
          m={[1, 2]}
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          {'>'}
        </Button>
        <Button
          m={[1, 2]}
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          disabled={!table.getCanNextPage()}
        >
          {'>>'}
        </Button>
        <Box>
          <Box>
            <strong>
              {table.getState().pagination.pageIndex + 1}
              {' '}
              of
              {' '}
              {table.getPageCount()}
            </strong>
          </Box>
          <Box>
            {' '}
            Go to page:
            <Input
              type="number"
              m={[4]}
              defaultValue={table.getState().pagination.pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                table.setPageIndex(page);
              }}
              width="60px"
            />
            {' '}

            <Select
              variant="outline"
              width="120px"
              value={table.getState().pagination.pageSize}
              onChange={(e) => {
                table.setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show
                  {' '}
                  {pageSize}
                </option>
              ))}
            </Select>

          </Box>
        </Box>
      </div>
      <div />
      <div>
        <Box m={[3]}>
          {table.getRowModel().rows.length}
          {' '}
          Rows
          <Button m={4} onClick={() => rerender()}>Force Rerender</Button>
        </Box>
      </div>
    </div>
  );
};
