import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { GoogleLogin } from '@react-oauth/google';
import { useAppDispatch } from '../app/hooks';
import { fetchMe, setToken } from '../features/me/me';

const Login = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [_, setCookie] = useCookies(['neptune_admin_cookie']);

  return (
    <GoogleLogin
      onSuccess={(token) => {
        dispatch(setToken(token.credential));
        dispatch(fetchMe(token.credential));
        setCookie('neptune_admin_cookie', token.credential, {
          path: '/',
          secure: true,
          sameSite: 'none',
        });
        navigate('/dashboard/');
      }}
      useOneTap
    />
  );
};

export default Login;
