import { Box, Button } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Plan } from '../features/plans/plans';
import { PlansTable } from './tables/Plans';

export interface PlansProps {
  data: Plan[]
}
export const Plans = ({ data }: PlansProps) => {
  const navigate = useNavigate();
  return (
    <Box>
      <Button onClick={() => navigate('/dashboard/plans/create')}>Create</Button>
      <PlansTable data={data} />
    </Box>
  );
};
