export const IsIpv4Valid = (value: string) => value.match(/^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/);

export const parseError = (payload: any) => {
  console.log('FF', payload?.code);
  try {
    return payload?.response?.data;
  } catch {
    return 'Something went wrong';
  }
};
