import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Client } from '../../http';
import { RootState } from '../../app/store';
import { parseError } from '../../utils';

export interface Customer {
  id: string;
  sk: string;
  first_name: string;
  last_name: number;
  email: string;
  phone: string;
  terms_accepted: boolean;
  marketing_accepted: boolean;
  stripe_customer_id: string;
}

export interface customerState {
  value: Array<Customer>;
  currentCustomer: Customer;
  nextPageToken: string;
  previousPageToken: string;
  error: string;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: customerState = {
  error: '',
  currentCustomer: {} as Customer,
  value: [],
  nextPageToken: '',
  previousPageToken: '',
  status: 'idle',
};

export const fetchCustomers = createAsyncThunk<
  any,
  string | undefined
>('customers/fetchCustomers', async () => {
  const response = await Client.get<Customer>(
    '/api/v1/admin/neptune/customers',
  );
  return response.data;
});

export const fetchCurrentCustomer = createAsyncThunk<
  Customer | {error: string},
  Customer,
  {state: RootState}
>('customers/fetchCurrentCustomer', async (userData) => {
  const { id } = userData;
  const response = await Client.get<Customer>(
    `/api/v1/admin/neptune/customers/${id}`,
  );
  return response.data;
});

export const createCustomer = createAsyncThunk<
  Customer,
  Customer,
  {state: RootState}
>('customers/createCustomer', async (svc: Customer, thunkAPI) => {
  try {
    const response = await Client.post<Customer>(
      '/api/v1/admin/neptune/customers',
      svc,
    );
    console.log('RESP', response);

    return response.data;
  } catch (e) {
    return thunkAPI.rejectWithValue(parseError(e));
  }
});

export const updateCustomer = createAsyncThunk<
  Customer,
  Customer,
  {state: RootState}
>('customers/updateCustomer', async (svc: Customer) => {
  const response = await Client.put<Customer>(
    '/api/v1/admin/neptune/customers',
    svc,
  );
  return response.data;
});

export const customersSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(fetchCurrentCustomer.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCurrentCustomer.fulfilled, (state, action) => {
        state.status = 'idle';
        state.currentCustomer = action.payload as Customer;
      })
      .addCase(fetchCurrentCustomer.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(fetchCustomers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCustomers.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload;
      })
      .addCase(fetchCustomers.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(createCustomer.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createCustomer.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value.push(action.payload);
      })
      .addCase(createCustomer.rejected, (state, action) => {
        state.status = 'failed';
        const { payload } = action;
        const { error } = payload as {error: string};
        state.error = error;
      })

      .addCase(updateCustomer.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateCustomer.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = state.value.flatMap((v) => (v.id === action.payload.id ? [action.payload] : [v]));
      })
      .addCase(updateCustomer.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const selectCustomers = (state: RootState) => state.customers.value;
export default customersSlice.reducer;
