import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { NBNCoAddress, NBNCoPlacesResponse } from '../../models/nbn';

export interface NBNCoPlacesState {
  value: NBNCoAddress[];
  status: 'idle' | 'loading' | 'failed';
}

const initialState: NBNCoPlacesState = {
  value: [] as NBNCoAddress[],
  status: 'idle',
};

export const fetchPlaces = createAsyncThunk(
  'nbnCoPlaces/fetchPlaces',
  async (payload: string) => fetch(`/api/v1/places/autocomplete?query=${payload}`)
    .then((resp) => resp.json())
    .then(({ suggestions }: NBNCoPlacesResponse) => suggestions || [])
    .catch((error) => {
      console.error('Error fetching places:', error);
      throw error;
    }),
);

export const nbnCoPlacesSlice = createSlice({
  name: 'nbnCoPlaces',
  initialState,
  reducers: {
    reset: (state) => {
      state.value = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPlaces.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPlaces.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value = action.payload;
      })
      .addCase(fetchPlaces.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const selectNBNCoPlaces = (state: RootState) => state.nbnCoPlaces;
export default nbnCoPlacesSlice;
