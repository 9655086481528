import { Box, Button } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Subscription } from '../features/subscriptions/subscriptions';
import { SubscriptionsTable } from './tables/Subscriptions';

export interface SubscriptionsProps {
  data: Subscription[]
}
export const Subscriptions = ({ data }: SubscriptionsProps) => {
  const navigate = useNavigate();
  return (
    <Box>
      <Button onClick={() => navigate('/dashboard/subscriptions/create')}>Create</Button>
      <SubscriptionsTable data={data} />
    </Box>
  );
};
