import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import meReducer from '../features/me/me';

import nbnCoAddress from '../features/nbn_co_places/nbnCoAddressSlice';
import nbnCoPlaces from '../features/nbn_co_places/nbnCoPlacesSlice';

import plansReducer from '../features/plans/plans';
import customersReducer from '../features/customers/customers';
import subscriptionsReducer from '../features/subscriptions/subscriptions';

export const store = configureStore({
  reducer: {
    nbnCoPlaces: nbnCoPlaces.reducer,
    nbnCoAddress: nbnCoAddress.reducer,
    me: meReducer,
    plans: plansReducer,
    customers: customersReducer,
    subscriptions: subscriptionsReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
