import {
  FormControl, FormErrorMessage, FormLabel, Input,
} from '@chakra-ui/react';
import React from 'react';
import {
  UseFormReturn,
} from 'react-hook-form';

export interface FormControlInputProps {
  id: string
  formHook: UseFormReturn
  validate: any
  label: string
}

export const FormControlInput = ({
  id, formHook, validate, label,
}: FormControlInputProps) => (

  <FormControl pt={2} key={id} isInvalid={!!formHook.formState.errors[id]}>
    <FormLabel htmlFor={id}>{label}</FormLabel>
    <Input
      id={id}
      {...formHook.register(id, {
        required: `${id} is required`,
        validate,
      })}
    />
    <FormErrorMessage>
      {(formHook.formState.errors[id]?.message?.toString())}
    </FormErrorMessage>
  </FormControl>
);
