import React, { ReactNode, ReactText } from 'react';

import {
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Link,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  BoxProps,
  FlexProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import {
  FiHome,
  FiTrendingUp,
  FiCompass,
  FiStar,
  FiSettings,
  FiMenu,
  FiBell,
  FiChevronDown,
} from 'react-icons/fi';
import { IconType } from 'react-icons';
import Logo from '../images/neptune-logo-white.png';
import NavBar from './NavBar';

interface LinkItemProps {
  name: string;
  icon: IconType;
  href: string;
}
const LinkItems: Array<LinkItemProps> = [
  { name: 'Subscriptions', icon: FiHome, href: '/dashboard/subscriptions' },
  { name: 'Plans', icon: FiTrendingUp, href: '/dashboard/plans' },
  { name: 'Customers', icon: FiCompass, href: '/dashboard/customers' },
  { name: 'Settings', icon: FiSettings, href: '/dashboard/settings' },
];

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

interface NavItemProps extends FlexProps {
  href: string;
  icon: IconType;
  children: ReactText;
}
const NavItem = ({
  href, icon, children, ...rest
}: NavItemProps) => (
  <Link href={href} style={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }}>
    <Flex
      align="center"
      p="4"
      mx="4"
      borderRadius="lg"
      role="group"
      cursor="pointer"
      _hover={{
        bg: 'teal.500',
        color: 'black',
      }}
      {...rest}
    >
      {icon && (
      <Icon
        mr="4"
        fontSize="16"
        _groupHover={{
          color: 'teal.500',
          bg: 'fff',
        }}
        as={icon}
      />
      )}
      {children}
    </Flex>
  </Link>
);

interface MobileProps extends FlexProps {
  onOpen: () => void;
}
const MobileNav = ({ onOpen }: MobileProps) => (
  <NavBar onOpen={onOpen} email="murat@recloud.com.au" avatarLink="https://2.gravatar.com/avatar/13c4adc29340dd872340aecd4a08664cfc6f19b8626fea98b7c411ccd20fa833?size=512" />
);

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => (
  <Box
    transition="3s ease"
    bg={useColorModeValue('black', 'gray.900')}
    borderRight="1px"
    borderRightColor={useColorModeValue('black', 'gray.900')}
    w={{ base: 'full', md: 60 }}
    pos="fixed"
    h="full"
    {...rest}
  >
    <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">

      <img
        alt="Neptune Internet logo"
        width="150px"
        src={Logo}
      />
      <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
    </Flex>
    {LinkItems.map((link) => (
      <NavItem href={link.href} key={link.name} icon={link.icon}>
        {link.name}
      </NavItem>
    ))}
  </Box>
);

export const SidebarWithHeader = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box minH="100vh" bg={useColorModeValue('black', 'gray.900')}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: 'none', md: 'block' }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }} p="4">
        {children}
      </Box>
    </Box>
  );
};
